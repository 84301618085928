import { uniq } from 'lodash';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import Vue from 'vue';

import config from '@/config';
import localeFr from '@/config/locales/fr.json';
import localeEn from '@/config/locales/en.json';
import listOfValues from './listOfValues';
import modelStore from './model';

Vue.use(Vuex);

const token = localStorage.getItem(`${config.appName}_token`);
let user;
const roles = {
  USER: { inherits: [] }, // can access the app
  BO: { inherits: ['USER'] }, // can access the ADMIN
  DOCTOR: { inherits: ['BO'] },
  ADMIN: { inherits: ['DOCTOR'] },
  SUPERADMIN: { inherits: ['ADMIN'] },
};
try {
  user = localStorage.getItem(`${config.appName}_user`)
    ? JSON.parse(localStorage.getItem(`${config.appName}_user`))
    : '';
} catch (err) {
  console.error(err);
}

function getExtendedRoles(role, state) {
  let myRoles = [];
  if (
    roles
    && roles[role]
    && roles[role].inherits
    && Array.isArray(roles[role].inherits)
  ) {
    myRoles = myRoles.concat(roles[role].inherits);
    roles[role].inherits.forEach(r => {
      myRoles = myRoles.concat(getExtendedRoles(r, state));
    });
  }
  return uniq(myRoles);
}


const vuexLocal = new VuexPersistence({
  key: `vuex_${config.appName}`,
  storage: window.localStorage,
});

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: { listOfValues, model: modelStore },
  state: {
    user,
    token,
    io: null,
    translations: { fr: localeFr, en: localeEn },
    locale: (user && user.locale) || localStorage.getItem(`${config.appName}_locale`) || config.defaultLocale,
    userExtendedRoles: [],
    roles,
    data: {
      resources: [],
      tasks: [],
    },
    trelloCardCount: localStorage.getItem(`${config.appName}_trelloCardCount`) || '',
  },
  mutations: {
    resources(state, resources) {
      state.data.resources = resources;
    },

    tasks(state, tasks) {
      state.data.tasks = tasks;
    },

    roles(state, appRoles) {
      state.roles = appRoles;
    },

    extendedRoles(state) {
      let myRoles = state.user.roles || [];
      myRoles.forEach(role => {
        myRoles = myRoles.concat(getExtendedRoles(role, state));
      });
      state.userExtendedRoles = myRoles;
    },

    io(state, io) {
      state.io = io;
    },

    user(state, currentUser) {
      state.user = currentUser;
      state.locale = currentUser.locale || config.defaultLocale;
      localStorage.setItem(`${config.appName}_user`, JSON.stringify(currentUser));
    },

    currentLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem(`${config.appName}_locale`, locale);
    },

    token(state, appToken) {
      this._vm.$http.defaults.headers.common.Authorization = `Bearer ${appToken}`;
      this._vm.$http.defaults.headers.Authorization = `Bearer ${appToken}`;
      state.token = appToken;
      localStorage.setItem(`${config.appName}_token`, appToken);
    },

    commitData(state, key, value) {
      state.data[key] = value;
    },

    trelloCardCount(state, value) {
      state.trelloCardCount = value;
      localStorage.setItem(`${config.appName}_trelloCardCount`, value);
    },
  },
  getters: {
  },
  actions: {
    logout({ commit }) {
      delete App.user;
      commit('user', '');
      commit('token', '');
      commit('models', []);
      return true;
    },

    user({ commit }, currentUser) {
      commit('user', currentUser);
      commit('extendedRoles');
      return true;
    },

    refreshUser({ commit, dispatch }) {
      const q = this._vm.$http.get('/api/auth/user?isBackOfficeMode=true');
      q.then(res => {
        App.user = res.data.user;
        localStorage.setItem(`${config.appName}_user`, JSON.stringify(res.data.user));
        commit('user', res.data.user);
        commit('extendedRoles');
      }).catch(err => {
        console.warn('refresh error', err);
        this._vm.$notify({
          title: err.response ? err.response.data : err,
          type: 'warning',
        });
        if (err.response && err.response.status === 404) {
          dispatch('logout');
        }
      });
      return q;
    },

    getTranslations(context) {
      const p = this._vm.$http.get(`/api/translations/${context.state.locale}`);

      p.then(res => {
        context.commit('translations', res.data);
      }).catch(err => {
        console.warn(err);
      });

      return p;
    },

    changeLocale(context, locale) {
      context.commit('currentLocale', locale);
      // context.dispatch('getTranslations');
    },
    // eslint-disable-next-line
    init(context) {
      //  return context.dispatch('getTranslations');
    },
  },
});

export default store;
